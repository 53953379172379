<template>
  <div>
    <ag-table
      :grid-options="gridOptions"
      :row-data="rowData"
      :rtl="false"
      @emitAddNewRecord="createRecord()"
      @emitDeleteMultipleRecords="deleteAnalytic($event, null , true)"
    />

    <vs-popup
      :active.sync="popupActive"
      title="تفاصيل الإحصائية"
    >
      <vs-progress
        color="primary"
        indeterminate
        v-if="isLoading"
      />
      <ValidationObserver
        ref="validationObserver"
        v-slot="{handleSubmit}"
      >
        <form @keydown.enter.prevent="handleSubmit(onSubmit)">
          <image-uploader
            :image-preview="imagePreview"
            :is-image-required="isImageRequired"
            @fileUploaded="fileUploaded"
            ref="imageUploader"
          />

          <div class="vx-row">
            <div class="vx-col sm:w-1/2 w-full">
              <label>المفتاح المميزة</label>
              <vs-input
                class="w-full"
                placeholder="القيمة المميزة"
                v-model="analytic.key"
              />
            </div>
            <div class="vx-col sm:w-1/2 w-full">
              <ValidationProvider
                name="القيمة"
                rules="required"
                v-slot="{ errors }"
              >
                <label>القيمة</label>
                <vs-input
                  class="w-full"
                  placeholder="القيمة"
                  v-model="analytic.value"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>

          <div class="vx-row mt-5">
            <div class="vx-col sm:w-1/2 w-full">
              <ValidationProvider
                name="المفتاح المقروء"
                rules="required"
                v-slot="{ errors }"
              >
                <label>المفتاح المقروء</label>
                <vs-input
                  class="w-full"
                  placeholder="المفتاح المقروء"
                  v-model="analytic.readable_key"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="vx-col sm:w-1/2 w-full">
              <ValidationProvider
                name="الأولوية"
                rules=""
                v-slot="{ errors }"
              >
                <label>الأولوية</label>
                <vs-input
                  class="w-full"
                  placeholder="الأولوية"
                  type="number"
                  v-model="analytic.priority"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>

          <div class="vx-row mt-5">
            <div class="vx-col sm:w-1/2 w-full mb-2">
              <label>نوع الإحصائية</label>
              <ValidationProvider
                name="نوع المدونة"
                rules="required"
                v-slot="{ errors }"
              >
                <v-select
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  :options="generalTypes"
                  label="label"
                  v-model="analytic.type"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>

          <div class="vx-row mt-5">
            <div class="vx-col sm:w-1/2 w-full mb-2">
              <vs-button
                :disabled="isLoading"
                @click.prevent="handleSubmit(onSubmit)"
                type="filled"
                ycolor="success"
              >
                حفظ
              </vs-button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </vs-popup>
  </div>
</template>

<script>
import AgTable from "@/app/shared/shared-components/ag-grid/AgTable";
import imageUploader from "@/app/shared/shared-components/ImageUploader";
import AgTableImageCell from "@/app/shared/shared-components/ag-grid/AgTableImageCell";
import AgTableBtnCell from "@/app/shared/shared-components/ag-grid/AgTableBtnCell";
import utilities from "@/app/shared/utilities";
import {RepositoryFactory} from "@/app/website/website-repositories/websiteRepositoryFactory";
import {SharedRepositoryFactory} from "@/app/shared/shared-repositories/sharedRepositoryFactory";
import vSelect from "vue-select";

const analyticsRepo = RepositoryFactory.get('analytics');
const sharedRepo = SharedRepositoryFactory.get('generalTypes');

export default {
  name: "AnalyticsMain",
  components: {
    AgTable,
    imageUploader,
    vSelect
  },
  data() {
    return {
      isLoading: true,
      gridOptions: null,
      gridApi: null,
      rowData: [],
      generalTypes: [],
      popupActive: false,
      imagePreview: null,
      isImageRequired: false,
      analytic: {
        id: null,
        key: '',
        image_url: '',
        value: '',
        readable_key: '',
        priority: '',
        type: ''
      }
    };
  },
  methods: {
    createColDefs() {
      let self = this;
      return [
        {
          headerName: 'المعرف',
          field: 'id',
          filter: 'agTextColumnFilter',
          suppressSizeToFit: true,
          width: 225,
          headerCheckboxSelection: true,
          checkboxSelection: true,
        },
        {
          headerName: 'الصورة',
          field: 'image_url',
          cellRendererFramework: AgTableImageCell
        },
        {
          headerName: 'القيمة المميزة',
          field: 'key',
          filter: 'agTextColumnFilter',
        }, {
          headerName: 'العنوان',
          field: 'readable_key',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'القيمة',
          field: 'value',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'الأولوية',
          field: 'priority',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'النمط',
          field: 'type',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'الإجراءات',
          field: 'id',
          cellRendererFramework: AgTableBtnCell,
          cellRendererParams: {
            deleteRecord: function (id, rowIndex) {
              self.deleteAnalytic(id, rowIndex);
            },
            deleteImage: function (id) {
              self.deleteImage(id);
            },
            editRecord: function (analytic) {
              self.editRecord(analytic);
            },
            actions: ['edit', 'delete', 'image-delete']
          },
        },
      ];
    },

    deleteAnalytic(ids, index, isMultiple = false) {
      this.isLoading = true;
      if (isMultiple)
        ids = ids.join(',');
      analyticsRepo.deleteAnalytic(ids).then((response) => {
        if (response.code === 200) {
          if (isMultiple)
            this.fetchAllAnalytics();
          else {
            this.rowData.splice(index, 1);
            this.rowData = [...this.rowData];
          }
        }
        this.isLoading = false;
      });
    },

    deleteImage(id) {
      this.isLoading = true;
      analyticsRepo.deleteAnalytic(id, true).then((response) => {
        if (response.code === 200) {
          let recordIndex = this.rowData.findIndex(element => element.id == id);
          this.rowData[recordIndex].image_url = null;
        }
        this.isLoading = false;
      });
    },

    // used to fire the dialog which response to add a new record in the table
    createRecord() {
      utilities.initObjectMembers(this.analytic);
      this.$refs.imageUploader.clearImage();
      this.imagePreview = null;
      this.openPopup();
    },

    // used to fire the dialog which response to edit current record in the table
    editRecord(analytic) {
      this.$refs.imageUploader.clearImage();
      Object.assign(this.analytic, analytic);
      this.imagePreview = this.analytic.image_url;
      this.openPopup();
    },

    openPopup() {
      this.$refs.validationObserver.reset();
      this.popupActive = true;
    },

    onSubmit() {
      this.isLoading = true;

      if (typeof this.analytic.image_url == "string")
        delete this.analytic.image_url;

      if (this.analytic.id) {
        this.updateAnalytic(this.analytic);
      } else {
        this.storeAnalytic(this.analytic);
      }
    },

    storeAnalytic(analytic) {
      analyticsRepo.storeAnalytic(analytic).then((response) => {
        this.rowData.unshift(response.data.data);
        this.handleResponse(response.data);
        this.isLoading = false;
      });
    },

    updateAnalytic(analytic) {
      analyticsRepo.updateAnalytic(analytic).then((response) => {
        let index = this.rowData.map((analytic) => {
          return analytic.id;
        }).indexOf(response.data.data.id);
        this.rowData[index] = response.data.data;
        this.rerender();
        this.handleResponse(response.data);
        this.isLoading = false;
      });
    },

    handleResponse() {
      this.popupActive = false;
    },

    fileUploaded(file) {
      this.analytic.image_url = file;
    },

    // used to rerender updated row
    rerender() {
      this.gridApi.setRowData(this.rowData);
    },

    fetchAllAnalytics() {
      analyticsRepo.fetchAllAnalytics(this.$route.params.key).then((data) => {
        this.rowData = data;
        this.isLoading = false;
      });
    },
  },
  created() {
    this.gridOptions = {};
    this.gridOptions.columnDefs = this.createColDefs();
    const $this = this;
    this.$watch(
      () => this.$route.params,
      async () => {
        $this.fetchAllAnalytics();
      },
      {immediate: true}
    );
    sharedRepo.fetchGeneralTypes('analytics').then((data) => {
      this.generalTypes = data;
    });
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
  }
};
</script>

<style scoped>

</style>
